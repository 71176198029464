import { PLACEMENT_TYPES } from 'components/product/ProductBox';
import { Layout } from 'components/Layout';
import { TrackingProductList } from 'types/tracking';
import MainDeal from 'components/MainDeal';
import SectionTitle from 'components/SectionTitle';
import { TextBanner } from 'components/Banner/TextBanner';
import { useResponsive } from 'hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import Head from 'next/head';
import logoImage from 'public/logo-og.png';
import config from 'next/config';
import { ImageBanners } from 'components/Banner/ImageBanners';
import { Z_INDEX } from 'constants/common';
import { useAppSelector } from 'redux/appStore';
import GeoLocationContext from 'components/location/GeoLocationContext';
import { LocationProductList } from 'components/location/homepage/LocationProductList';
import { HOMEPAGE_BANNER_HEIGHT_DESKTOP, HOMEPAGE_BANNER_HEIGHT_MOBILE } from 'constants/assets';
import { ListFooter } from 'components/product/ListFooter';
import NavigationButton from 'components/buttons/NavigationButton';
import { HOME_ROUTES } from 'constants/routes/home';
import { useLocationNavigationLink } from 'hooks/location/useLocationNavigationLink';
import {
  selectHighlightedProducts,
  selectMainDeal,
  selectMainProducts,
  selectNewProducts,
  selectPrimaryBanner,
  selectSecondaryBanners,
} from 'redux/homePage/homePage.slice';
import { selectTaxons, selectTextBanner } from 'redux/common/common.slice';
import { useTrackHomepage } from 'hooks/page/useTrackHomepage';
import CartReminder from '../cartItem/reminder/CartReminder';
import { useNewsletter } from 'hooks/newsletter/useNewsletter';
import { PopularProducts } from 'components/product/popular/PopularProducts';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { experiments } from 'components/experiment/experiment.registry';
import { useRouter } from 'next/router';
import { PRODUCT_CARD_SIZES } from 'constants/productCard/productCard';
import { ResponsiveProductCard } from 'components/productCard/ResponsiveProductCard';
import { ProductsRows } from 'components/productList/Row/ProductsRows';

export default function Home() {
  const router = useRouter();

  const taxons = useAppSelector(selectTaxons);
  const mainDeal = useAppSelector(selectMainDeal);
  const newProducts = useAppSelector(selectNewProducts);
  const mainProducts = useAppSelector(selectMainProducts);
  const highlightedProducts = useAppSelector(selectHighlightedProducts);

  const { isMobile, isLg, lgBreak } = useResponsive();
  const { isCustomerSubscribed } = useNewsletter();
  const { t } = useTranslation();

  const isInRoute = router.asPath.includes('/in/');

  const isAvdd9185 = useFeatureIsOn(experiments.AVDD_9185) && !isInRoute;

  const { publicRuntimeConfig } = config();
  const { addLocation } = useLocationNavigationLink();

  const metaDescription = t('app.ui.homepage.meta.description', {
    domain: publicRuntimeConfig.topLevelDomain,
  });
  const facebookDomainVerificationHash = 'alm54y7m7xcr2n56zojrdifjjn5k6n';

  const textBanner = useAppSelector(selectTextBanner);
  const secondaryBanners = useAppSelector(selectSecondaryBanners);
  const mainBanner = useAppSelector(selectPrimaryBanner);

  useTrackHomepage();

  return (
    <Layout
      taxons={taxons}
      title={t('app.ui.homepage.title')}
      hasTitleSuffix={false}
      type="homepage"
      enableLocationSearch={!isAvdd9185}
      footerOptions={{ showNewsletterBanner: !isCustomerSubscribed }}
    >
      <Head>
        <meta key="og:image" property="og:image" content={logoImage?.src} />
        <meta name="keywords" content={t('app.ui.homepage.meta.keywords')} />
        <meta name="description" content={metaDescription} />
        <meta property="og:description" content={metaDescription} />
        <meta name="facebook-domain-verification" content={facebookDomainVerificationHash} />
      </Head>

      {!isAvdd9185 && <GeoLocationContext />}

      <div className="container">
        {!!textBanner && <TextBanner banner={textBanner} />}

        <CartReminder />

        {isAvdd9185 ? <PopularProducts /> : <LocationProductList />}

        {mainBanner && (
          <div className="my-4">
            <ImageBanners
              banners={[mainBanner]}
              bannerHeight={isLg ? HOMEPAGE_BANNER_HEIGHT_DESKTOP : HOMEPAGE_BANNER_HEIGHT_MOBILE}
            />
          </div>
        )}

        <div
          className={`${Z_INDEX.level10} mt-4 relative grid grid-cols-2 gap-x-6 lg:gap-x-12 mb-6 lg:mb-12`}
        >
          {mainProducts.map((product, index) => (
            <div key={`${product.id}${index}`} className="snap-center">
              <ResponsiveProductCard
                initialRenderSize={PRODUCT_CARD_SIZES.XS}
                product={product}
                productDetailPlacement={
                  isLg ? PLACEMENT_TYPES.ON_TOP_OF_IMAGE : PLACEMENT_TYPES.UNDER_IMAGE
                }
                showLocationPin={false}
                priority={index < 2}
                productList={TrackingProductList.home}
                productPosition={index + 1}
                imageSizes={'40vw'}
              />
            </div>
          ))}
        </div>

        {secondaryBanners.length > 0 && (
          <div className="container p-0 mb-12 mt-4">
            <ImageBanners
              banners={secondaryBanners}
              bannerHeight={isLg ? HOMEPAGE_BANNER_HEIGHT_DESKTOP : HOMEPAGE_BANNER_HEIGHT_MOBILE}
            />
          </div>
        )}

        {!isAvdd9185 && (
          <div className="relative">
            <PopularProducts />
          </div>
        )}

        <div className="relative">
          <SectionTitle
            title={t('app.ui.user_new_deals_section_title')}
            href={HOME_ROUTES.NEW_DEALS}
            showLink={!isMobile}
          />
          <div className="relative">
            <div
              className={`${Z_INDEX.level10} mb-4 relative grid grid-cols-2 lg:grid lg:grid-cols-4 gap-x-4 gap-y-4`}
            >
              {newProducts.slice(0, 4).map((product, index) => (
                <div className="snap-center" key={product.id.toString() + index}>
                  <ResponsiveProductCard
                    initialRenderSize={PRODUCT_CARD_SIZES.XS}
                    product={product}
                    className={'w-42 h-full lg:w-full'}
                    productList={TrackingProductList.newProducts}
                    productPosition={index + 1}
                    imageSizes={`(min-width: ${lgBreak}px) 25vw, 40vw`}
                  />
                </div>
              ))}
            </div>
          </div>
          {isMobile && (
            <ListFooter>
              <NavigationButton href={HOME_ROUTES.NEW_DEALS} size={'medium'} buttonType={'primary'}>
                {t('app.ui.section.showall')}
              </NavigationButton>
            </ListFooter>
          )}
        </div>

        <MainDeal product={mainDeal} />

        <div className="relative">
          <SectionTitle
            title={t('app.ui.user_special_deals_section_title')}
            href={addLocation(HOME_ROUTES.SPECIAL_FOR_YOU_DEALS)}
            showLink={!isMobile}
          />
          <div className={`${Z_INDEX.level10} relative`}>
            <ProductsRows
              products={highlightedProducts}
              trackingListName={TrackingProductList.specialForYouProducts}
            />
          </div>
          {isMobile && (
            <ListFooter>
              <NavigationButton
                href={addLocation(HOME_ROUTES.SPECIAL_FOR_YOU_DEALS)}
                size={'medium'}
                buttonType={'primary'}
              >
                {t('app.ui.section.showall')}
              </NavigationButton>
            </ListFooter>
          )}
        </div>
      </div>
    </Layout>
  );
}
